import moment from "moment-timezone";
import React, { useState } from "react";
import isEmpty from "../../../components/isEmpty";

// Import the CSS
import "./TimezoneSelector.css";

// TimezoneSelector
const TimezoneSelector = (props) => {
  const [timezone, setTimezone] = useState(props.timezone || moment.tz.guess());

  const handleChange = (event) => {
    setTimezone(event.target.value);
    if (!isEmpty(props.selectTimeZone)) {
      props.selectTimeZone(event.target.value);
    }
  };

  return (
    <div className="timezone-selector-container">
      <div className="timezone-selector-wrapper">
        <label htmlFor="timezone-select">Select your timezone:</label>
        <select id="timezone-select" value={timezone} onChange={handleChange}>
          {moment.tz.names().map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </select>
        <span className="timezone-selector-hint"></span>
      </div>
      <div className="timezone-time-display">
        Start Time ({timezone}):{" "}
        <strong>
          {moment(props.start_time)
            // .tz(timezone)
            .format("dddd, MMMM Do YYYY, HH:mm:ss z")}
        </strong>
      </div>
      <div className="timezone-time-display">
        End Time ({timezone}):{" "}
        <strong>
          {moment(props.end_time)
            // .tz(timezone)
            .format("dddd, MMMM Do YYYY, HH:mm:ss z")}
        </strong>
      </div>
    </div>
  );
};

export default TimezoneSelector;
