import React, { useState, useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";
//JS
import SignUpForPayPalMerchant from "./SignUpForPayPalMerchant";
//API
import {
  getMerchantPartnerRefferals,
  getSellerMerchantId,
  getMerchantOnboardingStatus,
} from "../../../../utils/apis/paypal/paypal_merchant/paypalapi";

import { paypalCreateMerchantAPI } from "../../../../utils/apis/live/auth_live_broadcasts";
//CSS
import "./PayPalOnboarding.css";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//PayPalOnboarding
function PayPalOnboarding(props) {
  //api
  const [apiLoading, setApiLoading] = useState(true);
  //action_url to onboard(call getPayPalPartnerReferralActionURL() to get action_url)
  const [paypalActionUrl, setPayPalActionUrl] = useState(null);
  //after signup => retrieve merchant_id
  const [paypalMerchantId, setPayPalMerchantId] = useState("");
  //after merchant_id => retrieve merchant_status
  const [paypalMerchantStatus, setPayPalMerchantStatus] = useState(null);
  const [paypalEmailConfirmed, setPayPalEmailConfirmed] = useState(false);

  useEffect(() => {
    // https://songdrop.band/broadcast/ppaltest?merchantId=339666f86521&merchantIdInPayPal=QLNLYEFPY4KFQ&productIntentId=addipmt&isEmailConfirmed=true&accountStatus=BUSINESS_ACCOUNT&permissionsGranted=true&consentStatus=true&riskStatus=SUBSCRIBED_WITH_ALL_FEATURES
    //1, Check if merchant_id is already connected or not to SongDrop
    retrieveMerchantId();
    getPayPalPartnerReferralActionURL();
    //2, If not -> Let users to sign-up as PayPal Merchant on SongDrop
    //-> use connetPayPal function to start onboarding merchants

    //3, Webhook event
  }, []);

  //1 RETRIEVE MERCHANT ID IF CAN
  async function retrieveMerchantId() {
    setApiLoading(true);
    //
    try {
      const tracking_id = props.user.paypal_tracking_id;
      const result = await getSellerMerchantId(tracking_id);
      //
      setApiLoading(false);
      //✅Success: ACTION_URL_TO_RETRIEVE
      const merchant_id = result["merchant_id"];
      console.log("retrieveMerchantId", result);
      //SAVE MERCHANT_ID
      //=> RETRIEVE MERCHANT STATUS USING MERCHANT_ID
      retrieveMerchantStatus(merchant_id);
      //
      //❌Error: Merchant Id cannot be found => connect PayPal again
    } catch (error) {
      // console.log("retrieveMerchantId.ERROR", error.message);
      // alert(error.message);
      getPayPalPartnerReferralActionURL();
    }
  }

  //2, RETRIEVE MERCHANT STATUS IF CAN
  async function retrieveMerchantStatus(merchant_id) {
    setApiLoading(true);

    try {
      const result = await getMerchantOnboardingStatus(merchant_id);
      setApiLoading(false);
      console.log("PAYPAL.retrieveMerchantStatus", result);
      //
      const broadcast_id = props.broadcast.id;
      const email = props.user.email;
      const paypal_tracking_id = props.user.paypal_tracking_id;
      const paypal_merchant_id = merchant_id;
      const paypal_merchant_status = getPaypalMerchantStatus(
        result,
        "MOBILE_PAYMENT_ACCEPTANCE"
      ); //this has to be 'ACTIVE'
      const paypal_primary_email_confirmed = result["primary_email_confirmed"]; //this has to be 'true'
      const paypal_payments_receivable = result["payments_receivable"]; //this has to be 'true'
      const partner_client_id = getPaypalMerchantClientId(
        result,
        "partner_client_id"
      ); //this will get the merchant_client_id so it can be used in paypal button
      // console.log("paypal_merchant_client_id", paypal_merchant_client_id);
      setPayPalMerchantId(paypal_merchant_id);
      setPayPalEmailConfirmed(paypal_primary_email_confirmed);
      setPayPalMerchantStatus(paypal_merchant_status);
      console.log("partner_client_id", partner_client_id);
      if (isEmpty(partner_client_id)) {
        //onboard seller again
        setPayPalMerchantStatus(null);
        setPayPalEmailConfirmed(false);
        setPayPalMerchantId(null);
        setApiLoading(false);
        return;
      }
      //CALL_DB_API
      try {
        var bodyParams = [];
        bodyParams.push(`broadcast_id=${broadcast_id}`);
        bodyParams.push(`email=${email}`);
        bodyParams.push(`paypal_tracking_id=${paypal_tracking_id}`);
        bodyParams.push(`paypal_merchant_id=${paypal_merchant_id}`);
        bodyParams.push(`paypal_merchant_status=${paypal_merchant_status}`);
        bodyParams.push(
          `paypal_primary_email_confirmed=${paypal_primary_email_confirmed}`
        );
        bodyParams.push(
          `paypal_payments_receivable=${paypal_payments_receivable}`
        );
        bodyParams.push(`paypal_merchant_client_id=${partner_client_id}`);
        bodyParams.push(`address=${""}`);
        let bParameters = bodyParams.join("&");
        //✅Success: Saved
        const result = await paypalCreateMerchantAPI(bParameters);
        // console.log("paypalCreateMerchantAPI", result);
        //❌Error: Save failed
      } catch (error) {
        setApiLoading(false);
        console.log("PAYPAL.saveMerchantId_TO_DB.ERROR", error.message);
      }
      //
      //❌Error: Merchant Id cannot be found => connect PayPal again
    } catch (error) {
      setApiLoading(false);
      console.log("PAYPAL.retrieveMerchantStatus.ERROR", error.message);
    }
  }

  //3 IF FAILS => ONBOARD SELLER
  //BY DEFAULT USERS HAS TO CONNECT THEIR PAYPAL TO RECEIVE PAYMENTS FROM BUYERS
  async function getPayPalPartnerReferralActionURL() {
    setApiLoading(true);
    try {
      const tracking_id = props.user.paypal_tracking_id;
      const return_url = `https://songdrop.band/broadcast/${props.broadcast.id}`;
      //✅Success: ACTION_URL_TO_RETRIEVE
      const result = await getMerchantPartnerRefferals(tracking_id, return_url);
      //
      setApiLoading(false);
      // console.log("getPayPalPartnerReferralActionURL", result);
      const action_url = getActionUrl(result);
      if (!action_url) {
        throw new Error("Failed to get action URL from merchant data");
      }
      setPayPalActionUrl(action_url);
      // console.log("PayPal Onboarding Action URL Generated:", action_url);
      //❌Error: Validation failed
    } catch (error) {
      setApiLoading(false);
      alert(error.message);
      console.log(error);
    }
  }

  //Helper function to retrieve action_url from paypal response
  function getActionUrl(merchantDataResult) {
    const links = merchantDataResult.links;
    const actionUrlObject = links.find((link) => link.rel === "action_url");
    return actionUrlObject ? actionUrlObject.href : "";
  }

  function getPaypalMerchantStatus(result, result_name) {
    if (
      result &&
      Array.isArray(result.products) &&
      result.products.length > 0
    ) {
      // Loop through the products array to find the one with the name "MOBILE_PAYMENT_ACCEPTANCE"
      for (let i = 0; i < result.products.length; i++) {
        if (result.products[i].name === result_name) {
          return result.products[i].status; // Return the status if found
        }
      }
    }
    return ""; // Return null or a default value if the name is not found
  }

  function getPaypalMerchantClientId(result, key) {
    // Check if result and oauth_integrations are present and valid
    if (
      result &&
      Array.isArray(result.oauth_integrations) &&
      result.oauth_integrations.length > 0
    ) {
      // Loop through the oauth_integrations array
      for (let i = 0; i < result.oauth_integrations.length; i++) {
        const oauthIntegration = result.oauth_integrations[i];

        // Check if oauth_third_party exists and is valid
        if (
          oauthIntegration.oauth_third_party &&
          Array.isArray(oauthIntegration.oauth_third_party) &&
          oauthIntegration.oauth_third_party.length > 0
        ) {
          // Loop through the oauth_third_party array to find the key
          for (let j = 0; j < oauthIntegration.oauth_third_party.length; j++) {
            const oauthData = oauthIntegration.oauth_third_party[j];
            if (oauthData[key]) {
              return oauthData[key]; // Return the value of the specified key
            }
          }
        }
      }
    }
    return ""; // Return null if the key is not found
  }

  //1, USER HAS NOT BEEN SIGNED UP TO PAYPAL MERCHANT(default)
  //2, USER HAS BEEN SIGNED UP TO PAYPAL MERCHANT

  //VIEW
  return (
    <div className="paypal-onboarding">
      <h1>Payment methods</h1>
      <p>
        Provide better customer service by setting up hassle-free online payment
        method.{" "}
        <a href="https://paypal.com/business" target="_blank">
          Learn more
        </a>
      </p>
      <div className="paypal-connect">
        <img
          alt="PayPal icon"
          src="https://cdn.songdrop.cloud/docs/paypal-logo.png"
        ></img>
        {/* NO MERCHANT FOUND -> USER HAS TO SIGN UP*/}
        {!apiLoading &&
          !paypalMerchantId &&
          paypalMerchantStatus !== "Active" && (
            <div>
              <ul>
                <li>
                  Help drive conversion by offering customers a seamless
                  checkout experience with no setup or monthly fees
                </li>
                <li>
                  A single integrated payment system ensures you stay updated
                  with the latest payment methods
                </li>
                <li>
                  Accept major credit/debit cards and alternative local payment
                  methods through the PayPal wallet
                </li>
                <li>
                  When selling virtual tickets via PayPal, SongDrop takes a 15%
                  commission fee on every transaction.
                </li>
                {apiLoading && (
                  <button disabled>
                    <i className={apiFaIcon} />
                  </button>
                )}
              </ul>
              <div id="paypal-signup-container">
                {/* ACTION_URL IS MISSING */}
                {!paypalActionUrl && (
                  <div>
                    {!apiLoading && (
                      <button onClick={getPayPalPartnerReferralActionURL}>
                        Connect with PayPal
                      </button>
                    )}
                    {apiLoading && (
                      <button disabled>
                        <i className={apiFaIcon} />
                      </button>
                    )}
                  </div>
                )}
                {/* ACTION_URL RETRIEVED */}
                {paypalActionUrl && (
                  <SignUpForPayPalMerchant action_url={paypalActionUrl} />
                )}
              </div>
            </div>
          )}
        {apiLoading && (
          <button disabled>
            <i className={apiFaIcon} />
          </button>
        )}
        {/* MERCHANT FOUND -> USER SIGNED UP TO PAYPAL MERCHANT */}
        {paypalMerchantId && paypalMerchantStatus !== null && (
          <div className="merchant_box">
            {!paypalEmailConfirmed && (
              <div>
                Please complete your account setup in PayPal to start receiving
                the payments
              </div>
            )}
            <div className="merchant_status">
              <strong>Status:</strong>
              <p>{paypalMerchantStatus}</p>
            </div>
            <div className="merchant_id">
              <strong>MerchantId:</strong>
              <p>{paypalMerchantId}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PayPalOnboarding;
