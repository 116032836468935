function isEmpty(e) {
  switch (e) {
    case undefined:
    case "":
    case 0:
    case "0":
    case null:
    case false:
      return true;
    default:
      if (e instanceof Date && !isNaN(e)) {
        // A valid Date object is not empty
        return false;
      } else if (Array.isArray(e)) {
        // Handle empty arrays
        return e.length === 0;
      } else if (e instanceof File) {
        // Check if a File object is empty
        return false; // A File object is never empty
      } else if (e instanceof HTMLInputElement && e.type === "file") {
        // Check if a file input is empty
        return !e.files || e.files.length === 0; // Ensure files array is present and check its length
      } else if (typeof e === "object") {
        // Handle empty objects
        return e !== null && Object.keys(e).length === 0; // Handle other objects
      }
      return false;
  }
}

export default isEmpty;
