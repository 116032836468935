import React, { useRef, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import validateImageFile from "../../../components/imageValidator";
import { fileToS3, imgToS3 } from "../../../utils/apis/s3/UploadImage";
import { editBroadcastImg } from "../../../utils/apis/live/auth_live_broadcasts";
import { fileSize } from "../../../components/fileSize";

//CSS
import "./RadioImages.css";
//Fa
//fa icons
const faSuccess = "fa fa-check-circle";
const faError = "fa fa-exclamation-circle";
const faSpin = "fa fa-refresh spin-it";

//RadioImages class
function RadioImages(props) {
  //Img API uploading
  const [imgApiState, setImgApiState] = useState(0);
  const [bcgApiState, setBackgroundImgApiState] = useState(0);

  //Image Upload functions
  const coverImgRef = React.useRef(null);
  const chatImgRef = React.useRef(null);

  const [cover_img, setCoverImg] = useState({
    url: props.broadcast.cover_img_url,
    size: "",
  });

  const [chat_img, setChatImg] = useState({
    url: props.broadcast.chat_img_url,
    size: "",
  });

  //PROFILE_IMG_UPLOAD
  function coverFileInput(event) {
    const file = event.target.files[0];
    setImgApiState(1);
    const respectSquareSize = false;
    //1️⃣VALIDATE_IMAGE
    validateImageFile(file, respectSquareSize)
      .then(function (_) {
        //SET_IMAGE_TO_DISPLAY
        setCoverImg({
          url: URL.createObjectURL(file),
          size: fileSize(file.size),
        });
        //2️⃣UPLOAD_IMAGE_TO_STORAGE AND DB
        const is_cover_img = true;
        const folder = "radio" + "/" + props.broadcast.id;
        const filename = props.broadcast.id + "_" + "cover_img.jpeg";
        uploadImage(file, folder, filename, "cover_img_url", is_cover_img);
      })
      //🚫ERROR_FILE_NOT_VALID
      .catch((error) => {
        setImgApiState(3);
        // props.setApiError(error.message);
        alert(error.message);
      });
  }

  //BACKGROUND_WALLPAPER
  function bcgFileInput(event) {
    const file = event.target.files[0];
    setBackgroundImgApiState(1);
    //1️⃣VALIDATE_IMAGE
    validateImageFile(file)
      .then(function (_) {
        //SET_IMAGE_TO_DISPLAY
        setChatImg({
          url: URL.createObjectURL(file),
          size: fileSize(file.size),
        });
        //2️⃣UPLOAD_IMAGE_TO_STORAGE
        const is_cover_img = false;
        const folder = "radio" + "/" + props.broadcast.id;
        const filename = props.broadcast.id + "_" + "chat_img.jpeg";
        uploadImage(file, folder, filename, "chat_img_url", is_cover_img);
      })
      .catch((error) => {
        //🚫ERROR_FILE_NOT_VALID
        setBackgroundImgApiState(3);
        console.log(error);
        // props.setApiError(error.message);
        alert(error.message);
      });
  }

  //API
  const uploadImage = async (
    file,
    folder,
    filename,
    queryParameter,
    isSquare
  ) => {
    try {
      //1.UPLOAD_TO_S3
      const user_id = props.user.user_id;
      //2.RECEIVE_S3_URL ->
      const _folder = `${folder}`;
      var uploadURL = await fileToS3(file, _folder, filename);
      //3.UPLOAD_TO_DB
      var bodyParams = [];
      bodyParams.push(`${queryParameter}=${uploadURL}`);
      bodyParams.push(`live_id=${props.broadcast.id}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      console.log(bParameters);
      const response = await editBroadcastImg(bParameters);
      console.log("upload to db", response);
      //✅SUCCESS
      if (isSquare) {
        setImgApiState(2);
        setTimeout(function () {
          setImgApiState(0);
        }, 1000);
      }
      if (!isSquare) {
        setBackgroundImgApiState(2);
        setTimeout(function () {
          setBackgroundImgApiState(0);
        }, 1000);
      }
    } catch (err) {
      //🚫ERROR
      console.log(err);

      // props.setApiError(err.message);
      if (isSquare) {
        setImgApiState(3);
      } else {
        setBackgroundImgApiState(3);
      }
    }
  };

  //VIEW
  return (
    <div className="radioimages">
      <div className="image-container">
        <div className="imgitem">
          <div className="img-placeholder">
            <i className="fa fa-user-circle-o" />
          </div>
          <div className="img-image">
            <Zoom>
              <img
                id="p-image"
                alt=""
                style={{ width: "180px", height: "50px", borderRadius: "2px" }}
                src={cover_img.url}
                width="500"
                onLoad={(e) => onImgLoad(e)}
              />
            </Zoom>
            {imgApiState !== 0 && (
              <button className="img-api" disabled={true}>
                {imgApiState == 1 && <i className={faSpin} />}
                {imgApiState == 2 && <i className={faSuccess} />}
                {imgApiState == 3 && <i className={faError} />}
              </button>
            )}
          </div>
        </div>
        <div className="imgup-wrapper">
          <div>cover image/JPEG</div>
          <div>1600x1200</div>
          <div>{cover_img.size}</div>
          <form className="btn-wrapper">
            <button onClick={addProfileClick}>
              <i className="fa fa-paperclip" />
              <label>Add Cover</label>
            </button>
            <input
              type="file"
              accept="image/*"
              ref={coverImgRef}
              onChange={coverFileInput}
              style={{ display: "none" }}
            />
          </form>
        </div>
      </div>
      <div className="image-container">
        <div className="imgitem">
          <div className="img-placeholder">
            <i className="fa fa-file-image-o" />
          </div>
          <div className="img-image">
            <Zoom>
              <img
                id="p-image"
                alt=""
                style={{
                  width: "60px",
                  height: "100px",
                  borderRadius: "0px",
                }}
                src={chat_img.url}
                width="500"
                onLoad={(e) => onImgLoad(e)}
              />
            </Zoom>
            {bcgApiState !== 0 && (
              <button className="img-api" disabled={true}>
                {bcgApiState == 1 && <i className={faSpin} />}
                {bcgApiState == 2 && <i className={faSuccess} />}
                {bcgApiState == 3 && <i className={faError} />}
              </button>
            )}
          </div>
        </div>
        <div className="imgup-wrapper">
          <div>chat image/JPEG</div>
          <div>1080x1920</div>
          <div>{chat_img.size}</div>
          <form className="btn-wrapper">
            <button onClick={addBcgClick}>
              <i className="fa fa-paperclip" />
              <label>Add Image</label>
            </button>
            <input
              type="file"
              accept="image/*"
              ref={chatImgRef}
              onChange={bcgFileInput}
              style={{ display: "none" }}
            />
          </form>
        </div>
      </div>
    </div>
  );

  function onImgLoad(event) {
    var selectedFile = event.target; //.files[0];
    var reader = new FileReader();
    reader.onload = function (_event) {
      console.log(_event);
    };
  }

  function addProfileClick(event) {
    event.preventDefault();
    coverImgRef.current.click();
  }
  function addBcgClick(event) {
    event.preventDefault();
    chatImgRef.current.click();
  }
}

export default RadioImages;
