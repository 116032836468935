import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import moment from "moment";
//JS
import BroadcastStarted from "./BroadcastStarted";
//API
import { get_music_genres } from "./radio_api";
//CSS
import "./RadioItemList.css";

//
function getGroupById(group_id) {
  let groups = get_music_genres().filter(function (group) {
    return group.id === group_id;
  });
  if (!isEmpty(groups)) {
    return groups[0].title;
  }
}

//RadioItemList class
function ItemCard({
  isApiWorking,
  item,
  handleSelectEvent,
  editEvent,
  duplicateEvent,
  deleteEvent,
}) {
  const handleCardClick = (e) => {
    // Check if the target of the event is not one of the buttons
    if (!e.target.closest(".btn-flex")) {
      handleSelectEvent(item);
    }
  };
  //VIEW
  return (
    <div
      onClick={handleCardClick}
      className="radio_list_card"
      style={{ border: `2px solid ${item.bgColor}` }}
    >
      <a
        className="_publish"
        href={`${item.public_link}`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            backgroundColor: item.is_published === 0 ? "lightgray" : "green", // `${item.bgColor}`,
          }}
        >
          <i className="fa fa-external-link" />{" "}
          {item.is_published === 1 ? "PUBLISHED" : "DRAFT"}
        </div>
      </a>
      <div
        className="_content"
        style={{ backgroundColor: `${item.bgColor}30` }}
      >
        <div className="img-container">
          <img alt="" src={item.cover_img_url} />
        </div>
        <div className="">
          <BroadcastStarted startTime={item.start} endTime={item.end} />
        </div>
        <div className="_title">{item.title}</div>
        <p>
          <strong>{getGroupById(item.group_id)}</strong>
        </p>
        <div className="genre-container">
          {!isEmpty(item.genres) &&
            item.genres.map((genre, index) => {
              return (
                <div
                  key={index}
                  // title={titleWiki(genre)}
                  // onClick={() => openWikiForGenre(genre)}
                  className="genre"
                  style={{ backgroundColor: item.bgColor }}
                >
                  {genre}
                </div>
              );
            })}
        </div>
        {/* <p>Description: {item.description}</p> */}
        <div>
          Start Time:{" "}
          {isEmpty(item.start_time) ? (
            <i className="fa fa-calendar-plus-o" />
          ) : (
            moment(item.start).format("YYYY-MM-DD HH:mm:ss")
          )}
        </div>
        <div>
          End Time:{" "}
          {isEmpty(item.end_time) ? (
            <i className="fa fa-calendar-plus-o" />
          ) : (
            moment(item.end).format("YYYY-MM-DD HH:mm:ss")
          )}
        </div>
        <p>
          <strong>@{item.username}</strong>
        </p>
        <div className="broadcast_links">
          <a
            title="Live link broadcast"
            href={item.public_link}
            alt=""
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-external-link" /> {item.public_link}
          </a>
          <a
            title="Live link with chat"
            href={item.chat_link}
            alt=""
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-external-link" />{" "}
            <i className="fa fa-commenting" /> {item.chat_link}
          </a>
          <a
            title="Direct link for audio(Don't share this!)"
            href={item.url}
            alt=""
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-music" />{" "}
            {isEmpty(item.url) ? "MP3 URL" : item.url}
          </a>
          <a
            title="Edit link"
            href={`${window.location.href}/${item.id}`}
            alt=""
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-pencil" /> {`${item.edit_link}`}
          </a>
          <a
            title="Adverts link"
            href={`${item.edit_link}?ad`}
            alt=""
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fa fa-shopping-bag" /> {`${item.edit_link}?ad`}
          </a>
        </div>
      </div>
      <div className="btn-flex">
        <button onClick={(e) => editEvent(item)} disabled={isApiWorking}>
          <i className="fa fa-pencil" /> Edit
        </button>
        <button onClick={(e) => duplicateEvent(item)} disabled={isApiWorking}>
          <i className="fa fa-files-o" /> Duplicate
        </button>
        <button onClick={(e) => deleteEvent(item)} disabled={isApiWorking}>
          <i className="fa fa-trash" /> Delete
        </button>
      </div>
    </div>
  );
}

function RadioItemList(props) {
  return (
    <div className="radio_list">
      {props.radioItems.map((item) => (
        <ItemCard
          isApiWorking={props.isApiWorking}
          key={item.id}
          item={item}
          handleSelectEvent={props.handleSelectEvent}
          editEvent={props.editEvent}
          duplicateEvent={props.duplicateEvent}
          deleteEvent={props.deleteEvent}
        />
      ))}
    </div>
  );
}

export default RadioItemList;
